import styled from "styled-components";

export const ModalContent = styled.div`
	& > * {
		color: var(--primary-neutral-nightshade-800);
	}

	.callUs {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		max-width: 400px;
	}

	.email {
		max-width: 450px;
		h5 {
			margin-bottom: 2rem;
		}
	}

	a {
		color: var(--primary-neutral-nightshade-800);
	}
`;

export const ImageWrapper = styled.div`
	display: block;
	height: 80px;
	width: 80px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}
`;
