import React, { useEffect } from "react";
import {
	Text,
	Modal,
	Link,
	CloudinaryImage,
} from "@website-builder/ui/shared/elements";
import { ModalContent, ImageWrapper } from "./styles";
import { addToDataLayer } from "@website-builder/utilities/utils/utils.js";

const CallUsModal = (props) => {
	const {
		showModal,
		imageUrl,
		imageAltText,
		upperText,
		lowerText,
		onClose = () => {},
		tel,
		pageSection = null,
		...restProps
	} = props;

	useEffect(() => {
		if (showModal) {
			addToDataLayer({
				event: "call_link_click",
				page_section: pageSection,
			});
		}
	}, [showModal]);

	const handleCallLinkClick = () => {
		addToDataLayer({
			event: "call_start",
			page_section: pageSection,
			phone_no: tel,
		});
	};

	return (
		<Modal
			show={showModal}
			disableBackdropClick={true}
			onClose={onClose}
			{...restProps}
		>
			<ModalContent>
				<div className="callUs">
					<Text variant="headline_5">{upperText}</Text>
					<ImageWrapper>
						<CloudinaryImage
							url={imageUrl}
							lazyload
							dynamic
							alt={imageAltText}
							sizes={{ default: { height: 100 } }}
						/>
					</ImageWrapper>
					<Link
						href={`tel:${tel}`}
						onClick={handleCallLinkClick}
						iconConfig={{ iconName: "call" }}
					>
						<Text variant="paragraph_L">{lowerText}</Text>
					</Link>
				</div>
			</ModalContent>
		</Modal>
	);
};

export default CallUsModal;
